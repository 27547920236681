import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout
      heroTitle="Audio Engineering, Music Production, & Songwriting"
      heroSubTitle="Jimmy Ether Media"
      heroBackgroundImage="/images/jimmy-ether-on-stage.jpg"
    >
      <SEO
        title="Audio Engineering | Music Producer Jimmy Ether"
        ogDescription="Jimmy Ether is an audio engineer, mastering engineer, music producer, label owner, songwriter, and multi-instrumentalist providing tips and philosophy on making music."
        image="https://jimmyether.com/images/jimmy-ether-on-stage.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Jimmy Ether on Making Music</h2>
            <p>
              I've been involved in the music scene for over 30 years. As an artist, multiinstrumentalist musician, and songwriter. As a recording enginner, mastering engineer, and music producer. As a record label owner. And as a music related film documentarian, print journalist, and now print publisher.
            </p>
            <p>
              Practically everything I've learned over the years has come from those who mentored me along the way or gave me opportunities to learn from experieince. I hope this website will be the digital equivalent of that for you. I'll be sharing my approach, philosopy, and opinion on various topics around making music. And as often as possible, I'll try to let you in on the creative process as I work.
            </p>
            <p className="small">Live photo (above) by Mike White of <a href="https://www.deadlydesigns.com/" target="_blank">Deadly Designs</a>.</p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/jimmy-ether-office.jpg" alt="Audio Engineering - Jimmy Ether in Office" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Daily Music Production Live Streams</h2>
            <p>
              Every day I'm going to try to live stream as I work from 4:30-6pm eastern time. You can find me Monday, Wednesday, and Friday on TikTok, and Tuesdays and Thursdays on YouTube, Twitch, Facebook, and Twitter. For more information on how to keep updated on the schedule, visit the <a href="/live-streaming-music-production">music production live streaming</a> page.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Music Production Tutorials</h2>
            <p>
              As I have time, I make <a href="/music-production-tutorials">free music production tutorial articels and videos</a>. These include discussions about songwriting philosophy, recording techniques, mixing tips, releasing music, and pretty much anything that relates to the creative process. You can subscribe on <a href="https://www.youtube.com/channel/UC_wJrTHrWsYY3VvNxYMkBfg" target="_blank">YouTube</a> and <a href="https://www.tiktok.com/@jimmyether" target="_blank">follow me on TikTok</a> to keep up to date. TikTok's music community is often where ideas for the longer format articles and YouTube videos originate. So that's a good place to intract with me if there is something specifc you want me to cover. Or leave me a message below. I'm always happy to hear from you, even when you disagree with me.
            </p>
            <h2>Recent Music Production Tutorials, Articles, and Videos</h2>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>LUFS and The Loudness War</h3>
            <p>
              For decades mastering engineers have fought The Loudness Wars. I deep-dive into why it happenend, if there is any merit, if it is actually over, and how I approach masterting today for streaming and HD.
            </p>
            <p>
              <QuickLinkButton
                content={"Read More or Watch Video"}
                url={"/lufs-and-the-loudness-war/"}
                icon={"play circle"}
              />
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/lufs-and-the-loudness-war-high-def.jpg" alt="LUFS and The Loudness War" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/CADM179.jpg" alt="Demonstration of CADM179 Microphone" />
          </div>
        }
        textRight={
          <div>
            <h3>Demonstration of CADM179 Microphone</h3>
            <p>
              Demonstration and review of the inexpensive CAD M179 Microphone with stereo examples used as a Blumlein pair.
            </p>
            <p>
              <QuickLinkButton
                content={"Read More or Watch Video"}
                url={"/cad-m179-review/"}
                icon={"play circle"}
              />
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Got a question? Or want to give me a piece of your mind? All good, fill out the form.</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
